import { equipments } from "@/api/equipment/useEquipmentQuery";
import { MonitoringBox } from "@/features/monitoring/components/parentsBox/Monitoring";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { Main } from "@/features/standard/Main";
import { BASE_URL } from "@/instance/axios";
import { MultiSelect } from "@mantine/core";
import { AuthSignupPost201ResponseEquipment } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSocket } from "socket.io-react-hook";


const initialMonitoringData = {
    "denominatorLoad": 0,
    "equipmentCode": "OP05",
    "equipmentName": "OP05",
    "errorName": "-",
    "errorNumber": 0,
    "idleTime": "02:04:45",
    "idleTimeCreatedAt": "2024-10-30T02:36:27.824098359Z",
    "influxEquipmentCode": "P0200092",
    "keyCamStatus": "연속",
    "keyCamStatusNumber": 3,
    "limitCounter": 3000,
    "loadRatio": 0,
    "moldCavity": 0,
    "msPerUnit": 1859.93,
    "numeratorLoad": 0,
    "presetCounter": 778,
    "pressStatus": "동작 중",
    "pressStatusNumber": 1,
    "remainTime": "01:07:08",
    "runTime": "01:10:22",
    "runTimeCreatedAt": "2024-10-30T02:41:40.394123701Z",
    "runTimeRatio": 36.06389339711284,
    "spm": 32,
    "totalCounter": 21187,
    "totalTon": 0,
    "itemName": 'BRKT ENG MTG RR(중)',
    'itemCode': 400003
};

const Monitoring = Object.assign({}, Main, {
    Parents: Parents,
});

const createUserActionOrCondition = (selectedOptions: string[]) => {
    // 선택된 옵션이 있으면 각 옵션을 쿼리 조건으로 변환
    if (selectedOptions?.length > 0) {
        const conditions = selectedOptions.map(option => ({ $eq: option }));

        return conditions;
    }

    // 선택된 옵션이 없으면 빈 배열 반환
    return [];
};

export const MonitoringTable = () => {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [dummyData, setDummyData] = useState(initialMonitoringData);

    const userActionConditions = createUserActionOrCondition(selectedOptions);

    const { data: equipmentData } = useQuery(equipments?.find({
        query: {
            $and: [
                {
                    influxEquipmentCode: {
                        $and: [
                            { $not: null },
                            { $not: '' },
                        ],
                    },
                },
            ],
        },
        sort: "seq, id",
        populate: ['location', "works"],
    }));

    const { data: selectEquipmentData } = useQuery(equipments?.find({
        query: {
            $and: [
                {
                    influxEquipmentCode: {
                        $and: [
                            { $not: null },
                            { $not: '' },
                            { $or: userActionConditions }
                        ],
                    },
                },
            ],
        },
        sort: "seq, id",
        populate: ['location', "works"],
    }));

    const [monitoringData, setMonitoringData] = useState<Array<any>>([]);
    const equipmentCode = selectEquipmentData?.data &&
        selectEquipmentData?.data?.map((data: AuthSignupPost201ResponseEquipment) => data?.code);

    const { socket, error } = useSocket(BASE_URL, {
        autoConnect: true,
    });
    // console.log(equipmentCode)
    useEffect(() => {
        // monitoringData 내의 각 항목의 option 속성이 selectedOptions 배열에 포함되어 있는지 확인
        const filteredData = monitoringData.filter(data => data?.option && selectedOptions.includes(data?.option));
        setMonitoringData(filteredData);

        equipmentCode?.forEach((code, index) => {
            const roomName = `PM_${code}`;
            socket.on(roomName, (message: any) => {
                setMonitoringData((prevData: any) => {
                    const newData = [...prevData];
                    newData[index] = message;
                    return newData;
                });
            });
            socket.emit(
                "call",
                "socket.join",
                { room: roomName },
                function (err: any, res: any) {
                    if (err) {
                        console.error(err);
                    }
                }
            );
        });
        // 컴포넌트가 언마운트될 때 소켓의 이벤트 리스너를 제거
        return () => {
            equipmentCode?.forEach((code) => {
                const roomName = `PM_${code}`;
                socket.off(roomName);
            });
        };
    }, [socket, selectEquipmentData, selectedOptions]); // selectedOptions를 의존성 배열에 추가

    // equipmentData를 MultiSelect 컴포넌트에서 사용할 수 있는 형태로 변환
    const multiSelectData = equipmentData?.data?.reduce((acc: { value: string; label: string; }[], equipment: AuthSignupPost201ResponseEquipment) => {
        if (equipment?.influxEquipmentCode) { // influxEquipmentCode가 undefined가 아닌 경우에만 배열에 추가
            acc.push({
                value: equipment?.influxEquipmentCode,
                label: equipment?.name
            });
        }
        return acc;
    }, []);

    useEffect(() => {
        const updateData = () => {
            setDummyData(prevData => {
                const newSpm = Math.floor(Math.random() * (40 - 30 + 1)) + 35;
                const newPresetCounter = Math.min(prevData.presetCounter + 1, prevData.limitCounter);
                const newTotalCounter = prevData.totalCounter + 1;

                let [runHours, runMinutes, runSeconds] = prevData.runTime.split(':').map(Number);
                let idleTimeIncrease = 0; // 비가동시간 증가 변수

                // 상태에 따라 시간 증가 로직
                if (prevData.pressStatus === "동작 중") {
                    runSeconds += 1; // 가동시간 증가
                    if (runSeconds === 60) {
                        runSeconds = 0;
                        runMinutes += 1;
                    }
                    if (runMinutes === 60) {
                        runMinutes = 0;
                        runHours += 1;
                    }
                } else if (prevData.pressStatus === "대기 중") {
                    idleTimeIncrease = 1; // 대기 중일 때 비가동시간 증가
                }

                const newRunTime = `${String(runHours).padStart(2, '0')}:${String(runMinutes).padStart(2, '0')}:${String(runSeconds).padStart(2, '0')}`;

                let [idleHours, idleMinutes, idleSeconds] = prevData.idleTime.split(':').map(Number);
                idleSeconds += idleTimeIncrease; // 비가동시간 증가
                if (idleSeconds === 60) {
                    idleSeconds = 0;
                    idleMinutes += 1;
                }
                if (idleMinutes === 60) {
                    idleMinutes = 0;
                    idleHours += 1;
                }
                const newIdleTime = `${String(idleHours).padStart(2, '0')}:${String(idleMinutes).padStart(2, '0')}:${String(idleSeconds).padStart(2, '0')}`;

                const totalIdleSeconds = idleHours * 3600 + idleMinutes * 60 + idleSeconds;
                const totalRunSeconds = runHours * 3600 + runMinutes * 60 + runSeconds;
                const newRunTimeRatio = totalIdleSeconds > 0 ? ((totalIdleSeconds - totalRunSeconds) / totalIdleSeconds) * 100 : 0;

                let [remainHours, remainMinutes, remainSeconds] = prevData.remainTime.split(':').map(Number);
                if (remainHours > 0 || remainMinutes > 0 || remainSeconds > 0) {
                    remainSeconds -= 1;
                    if (remainSeconds < 0) {
                        remainSeconds = 59;
                        remainMinutes -= 1;
                    }
                    if (remainMinutes < 0) {
                        remainMinutes = 59;
                        remainHours -= 1;
                    }
                }
                const newRemainTime = `${String(remainHours).padStart(2, '0')}:${String(remainMinutes).padStart(2, '0')}:${String(remainSeconds).padStart(2, '0')}`;

                // 80% 확률로 "동작 중", 20% 확률로 "대기 중"
                const randomStatus = Math.random() < 0.8 ? "동작 중" : "대기 중";

                return {
                    ...prevData,
                    spm: newSpm,
                    presetCounter: newPresetCounter,
                    totalCounter: newTotalCounter,
                    runTime: newRunTime,
                    idleTime: newIdleTime,
                    runTimeRatio: newRunTimeRatio,
                    remainTime: newRemainTime,
                    pressStatus: randomStatus // 랜덤 상태 변경
                };
            });

            // 3초 ~ 6초 사이의 랜덤한 시간 간격으로 다음 업데이트 설정
            const randomInterval = Math.floor(Math.random() * (10000 - 3000 + 1)) + 3000;
            setTimeout(updateData, randomInterval);
        };

        const randomInitialInterval = Math.floor(Math.random() * (10000 - 3000 + 1)) + 3000;
        const initialTimeout = setTimeout(updateData, randomInitialInterval);

        return () => clearTimeout(initialTimeout);
    }, []);

    return (
        <>
            <MultiSelect
                data={multiSelectData || []}
                placeholder="검색할 기계를 선택해주세요."
                value={selectedOptions}
                onChange={setSelectedOptions}
            />
            <Monitoring.Parents>
                {[dummyData]?.map((data: any, index: number) => {
                    return (
                        <MonitoringBox data={data} index={index} />
                    )
                })}

            </Monitoring.Parents>
        </>
    )
}