import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ActionBarContainer, LeftActionBar, RightActionBar } from "@/features/work/WorkView/ActionBar";
import { Button } from "@mantine/core";
import { ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";
import { OrderStatusForm } from "../form/statusForm";
import { OrderUpdateForm } from "../form/updateForm";
import { OrderDetailHeaderProps } from "./orderDetailHeader";


export const OrderDetailActionBar = (params : OrderDetailHeaderProps) => {
    const { data } = params;
    const { openModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: deleteMutate } = useMutation(
        (params : ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
            mutatePurchaseOrders.delete(params).mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("purchaseOrders");
            }
        }
    )

    const deleteOrder = () => {
        if (window.confirm("정말 삭제하시겠습니까?")) {
            deleteMutate(
                {
                    purchaseOrderId: data?.id ?? 0
                },
                {
                    onSuccess: (res) => {
                        if (res.status === 200) {
                            customAlert(
                                "발주 삭제에 성공하였습니다.",
                                "삭제 성공",
                                "green"
                            )
                        }
                    },
                    onError: (error : any) => {
                        customAlert(
                            `${error.response?.data?.message}` ?? "발주 삭제에 실패하였습니다.",
                            "삭제 실패",
                            "red"
                        )
                    }
                }
            )
        }
    }
    return (
        <ActionBarContainer>
            <LeftActionBar>
                <Button 
                variant="outline"
                onClick={() => openModal(
                    <OrderStatusForm formatterProps={data} />,
                    null,
                    "발주상태 변경"
                )}
                >발주상태 변경</Button>
            </LeftActionBar>

            <RightActionBar>
                <Button color="orange"
                    rightIcon={<IconEdit />}
                    onClick={() => openModal(
                        <OrderUpdateForm formatterProps={data} />,
                        null,
                        "발주 수정"
                    )}
                >수정</Button>
                <Button color="red"
                    rightIcon={<IconTrash />}
                    onClick={deleteOrder}
                >삭제</Button>
            </RightActionBar>
        </ActionBarContainer>
    )
}