import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
    Chip,
    Flex,
    Pagination,
    useMantineColorScheme
} from "@mantine/core";
import { useEffect, useReducer, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { FormatterProps, SelectColumn } from "react-data-grid";

import useGetDeliveryQuery from "@/api/delivery/useGetDeliveryQuery";
import { customFunctions } from "@/config/customFunction";
import { wmsLogsHeader } from "@/constants/columnHeader";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { ChipsBox } from "@/features/standard/Chips/ChipsBox";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { theme } from "@/styles/theme";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { wmsStatus } from "@/utils/wmsStatus";
import { useSetState } from "@mantine/hooks";
import { WmsLogsGet200ResponseRowsInner, WmsLogsGet200ResponseRowsInnerActionTypeEnum } from "@sizlcorp/sizl-api-document/dist/models/src/model";
import { useTranslation } from "react-i18next";

interface ColorThemeProps {
    isDarkMode: boolean;
}

type State = {
    [key: string]: boolean;
}

type Action = {
    type: 'toogle';
    name: string;
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'toogle':
            return { ...state, [action.name]: !state[action.name] };
        default:
            throw new Error();
    }
}

export const WmsLogsTable = ({ onRowSelect }: any) => {
    // const [rows, setRows] = useState<any>(createRows);
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const isDarkMode: boolean = colorScheme === "light";

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [formatterProps, setFormatterProps] = useState<
        FormatterProps<WmsLogsGet200ResponseRowsInner, unknown> | undefined
    >();
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );

    const [state, dispatch] = useReducer(reducer, {
        "STOCK_SERIAL": true,
        "INCOMING": true,
        "OUTGOING": true,
        "TRANSFER": true,
        "TRANSFER_INPUT": true,
        "BATCH": true,
        "DILIGENCE": true,
        "CLOSE": true,
        "FLUSH": true,
    });

    customFunctions.ADD_TRANSFORM_TYPE && (state["TRANSFORM"] = true);

    const [formatterPropsData, setFormatterPropsData] = useState<WmsLogsGet200ResponseRowsInner>();
    const [activePage, setPage] = useState(1);

    const [query, setQuery] = useState({
        $or: [
            {
                actionType: Object.keys(state).reduce(
                    (result: WmsLogsGet200ResponseRowsInnerActionTypeEnum[][], key: string) => {
                        if (state[key as keyof typeof state]) {
                            const actionType = WmsLogsGet200ResponseRowsInnerActionTypeEnum[key as keyof typeof WmsLogsGet200ResponseRowsInnerActionTypeEnum];
                            if (typeof actionType === 'string') {
                                result.push(actionType as unknown as WmsLogsGet200ResponseRowsInnerActionTypeEnum[]);
                            }
                        }
                        return result;
                    }, [])
            }
        ]
    });
    // 필터 query
    const [queryFilter, setQueryFilter] = useSetState({ $and: [] });

    useEffect(() => {
        setQuery(() => {
            const newQuery = { $or: [] };
            const actionTypes: Array<string> = [];
            const transferConditions: Array<{ $and: { actionFrom: string; actionType: string } }> = [];

            Object.keys(state).forEach((key) => {
                if (state[key as keyof typeof state] === true) {
                    if (key === 'TRANSFER') {
                        transferConditions.push({ $and: { actionFrom: 'WEB', actionType: "TRANSFER" } });
                    } else if (key === 'TRANSFER_INPUT') {
                        transferConditions.push({ $and: { actionFrom: 'ACTION', actionType: "TRANSFER" } });
                    } else {
                        actionTypes.push(key);
                    }
                }
            });

            if (actionTypes.length > 0) {
                newQuery.$or.push({ actionType: actionTypes } as never);
            }

            if (transferConditions.length > 0) {
                newQuery.$or.push(...transferConditions as any as never[]);
            }

            if (actionTypes.length === 0 && transferConditions.length === 0) {
                return { $or: [{ actionType: [] }] };
            }

            return newQuery;
        });
    }, [state, dispatch]);

    // 필터와 버튼을 눌렀을 때의 query를 합친다.
    const mergedQuery = {
        $and: [
            ...queryFilter.$and,
            ...(query?.$or.length ? [query] : []),
        ]
    };

    const [sort, setSort] = useState([]);
    const [search, setSearch] = useState<SearchProps>({
        search: "",
        searchFields: [],
        pageSize: "10"
    });

    const { openModal } = useModal();
    const { t } = useTranslation();
    const searchFieldsHeader = wmsLogsHeader.filter(
        (wmsLogs) => wmsLogs.category === "text"
    );

    // 검색조건이 변경되면 current 페이지를 1로 초기화
    const isSort = sort.length;

    const { data: wmsLogsData, refetch } = useGetDeliveryQuery({
        query: mergedQuery,
        search: search.search,
        searchFields: search.searchFields.length
            ? search.searchFields
            : searchFieldsHeader.map((wmsLogs) => wmsLogs.value),
        activePage: activePage,
        pageSize: search.pageSize,
        sort: sort.length ? sort.join(",") : "-id",
        populate: ["locationName", "lot"]
    });

    useEffect(() => {
        setPage(1);
        refetch();
    }, [query.$or.length, search.search, search.searchFields.length, search.pageSize, isSort, refetch]);

    const rows: readonly WmsLogsGet200ResponseRowsInner[] =
        wmsLogsData?.data.rows ?? [];
    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

    // 부모에게 선택된 row를 넘겨주기 위한 함수
    useEffect(() => {
        const selectedRowId = selectedRows.values().next().value;
        if (selectedRowId) {
            const selectedRow = rows.find((row) => row.id === selectedRowId);

            setFormatterPropsData(selectedRow);
        } else {
            setFormatterPropsData(undefined);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (formatterPropsData) {
            onRowSelect(selectedRows, formatterPropsData, selectedRowsRef);
        }
    }, [selectedRows, formatterPropsData]);

    const columns: readonly Column<WmsLogsGet200ResponseRowsInner>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
            cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
        },
        {
            key: "id",
            name: "로그번호",
            sortable: true,
            resizable: true,
            width: 80
        },
        {
            key: "purchaseOrderItemId",
            name: "수주번호",
            sortable: true,
            resizable: true,
            width: 80,
        },
        {
            key: "actionType",
            name: "실적종류",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <span>
                        {row.actionFrom === "ACTION" && row.actionType === "TRANSFER" ? "자재투입" : t(row.actionType as WmsLogsGet200ResponseRowsInnerActionTypeEnum)}
                    </span>
                );
            }
        },
        {
            key: "actionParam",
            name: "상세정보",
            sortable: true,
            resizable: true,
            width: 500,
            cellClass: css`
        justify-content: flex-end;
      `,
            formatter: ({ row }) => {
                const actionType = row.actionType;

                if (actionType === 'TRANSFER') {
                    return (
                        <span>
                            {`로트명 : ${row.lotInfo?.name ?? ''} 
                            품목코드 : ${row.lotInfo?.itemCode ?? ''} 
                            수량 : ${setToLocaleString(row.actionParam?.params?.quantity ?? '0')}`}
                        </span>
                    )
                } else if (actionType === 'BATCH') {
                    return (
                        <span>
                            {`로트명 : ${row.batchLotInfo?.map((lotInfo) => lotInfo.lotInfo?.name).join(', ') ?? ''} 
                            품목코드 : ${row.batchLotInfo?.map((lotInfo) => lotInfo.lotInfo?.itemCode).join(', ') ?? ''}
                            수량 : ${row.batchLotInfo?.map((lotInfo) => setToLocaleString(lotInfo.quantity)).join(', ') ?? ''}`}
                        </span>
                    )
                } else if (actionType === 'TRANSFORM') {
                    return (
                        <span>
                            {/* Add your code here */}
                        </span>
                    )
                }

                return (
                    <span>
                        {`로트명 : ${row.actionParam?.params?.lotInfo?.name ?? ''}
                            품목코드 : ${row.actionParam?.params?.itemCode ?? ''}
                            수량 : ${setToLocaleString(row.actionParam?.params?.quantity ?? '0')}`}
                    </span>
                );
            },
        },
        {
            key: "locationCode",
            name: "출고창고",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row.actionParam?.params?.locationCode ||
                                    row.actionParam?.params?.sourceLocationCode || row.actionParam?.params?.fromLocationCode} />,
                                null,
                                "",
                                false
                            )
                        }}
                    >
                        {row.actionParam?.params?.sourceLocationName}
                    </DetailLink>
                );
            }
        },
        {
            key: "actionFrom",
            name: "입고창고",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => {
                            openModal(
                                <LocationDetailForm locationCode={row.actionParam?.params?.locationCode ||
                                    row.actionParam?.params?.targetLocationCode || row.actionParam?.params?.toLocationCode} />,
                                null,
                                "",
                                false
                            )
                        }}
                    >
                        {row.actionParam?.params?.targetLocationName}
                    </DetailLink>
                );
            }
        },
        {
            key: "creatorUserCode",
            name: "작업자",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <DetailLink
                        onClick={() => {
                            openModal(<UsersDetailForm UserCode={row.creatorUserCode} />, null, "", false,)
                        }}
                    >
                        {row.creatorUserCode}
                    </DetailLink>
                );
            }
        },
        {
            key: "createdAt",
            name: "생성일",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                return (
                    <span>
                        {timeUtil(row.createdAt ?? '')}
                    </span>
                );
            },
        },
    ];

    return (
        <InventoryTableWrapper>
            <GridWrapper isDarkMode={isDarkMode}>
                <TableWrapper>
                    <RetrieveWrapper>
                        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                            <CustomFilter filterType={wmsLogsHeader} setQuery={setQueryFilter} />
                            <CustomSorter sorterType={wmsLogsHeader} setSort={setSort} />
                        </Flex>
                        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} />
                    </RetrieveWrapper>
                    <RetrieveWrapper>
                        <ChipsBox>
                            {
                                Object.keys(wmsStatus).map((key) => (
                                    <Chip
                                        variant="filled"
                                        size="sm"
                                        radius="xs"
                                        key={key}
                                        checked={state[key] || false}
                                        onClick={() => dispatch({ type: 'toogle', name: key })}
                                    >
                                        {wmsStatus[key]}
                                    </Chip>
                                ))
                            }
                        </ChipsBox>
                    </RetrieveWrapper>
                    <DataGrid
                        columns={columns ?? []}
                        rows={rows as any}
                        rowHeight={40}
                        rowKeyGetter={(row: WmsLogsGet200ResponseRowsInner) => row.id}
                        sortColumns={sortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            selectedRowsRef.current = e;
                        }}
                    />
                </TableWrapper>
                <PaginationWrapper>
                    <Pagination
                        onChange={setPage}
                        value={activePage}
                        total={wmsLogsData?.data?.totalPages ?? 0}
                        size="lg"
                        radius="sm"
                    />
                </PaginationWrapper>
            </GridWrapper>
        </InventoryTableWrapper>
    );
};

const InventoryTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
        props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
        props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;