import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";

import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { PurchaseOrderCreateForm } from "@/features/purchaseOrder/form/createForm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { ScmApiPurchaseOrdersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";
import customAlert from "../../alert/alert";
import { PurchaseOrdersOption, usePurchaseOrderAutoComplete, usePurchaseOrdersCode } from "./auto-useGetPurchaseOrderQuery";

interface PurchaseOrderProps extends React.ComponentPropsWithoutRef<"div"> {
  group?: string;
  label: string;
  value: string;
}

export interface PurchaseOrderAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (purchaseCode: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
  direction: "INBOUND" | "OUTBOUND"
}

// 입력값: value (item 모델의 code)
// 출력값: onChange (item 모델의 code)

export const PurchaseOrderAutoComplete = (params: PurchaseOrderAutoCompleteProps) => {
  const {
    value: purchaseCode,
    onChange,
    maxDropdownHeight,
    width,
    direction,
    ...etcParams
  } = params;

  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = usePurchaseOrderAutoComplete(
    focused,
    searchKeyword,
    direction
  );
  const { data: initialOptions } = usePurchaseOrdersCode(!!purchaseCode, purchaseCode ?? null);

  const selectOptions = [...(options ?? []), ...(initialOptions ?? [])].reduce(
    (unique: PurchaseOrderProps[], option: PurchaseOrderProps) => {
      return unique.some((u) => u.value === option.value)
        ? unique
        : [...unique, option];
    },
    []
  );

  const SelectItem = forwardRef<HTMLDivElement, PurchaseOrderProps>(
    ({ group, label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex direction={"column"}>
            <Text>이름 : {name}</Text>
            <Text size={"xs"} color="#666666">
              수주번호 : {code}
            </Text>
          </Flex>
          <Text fz="xs">{group}</Text>
        </Flex>
      </div>
    )
  );

  let selectedItem = initialOptions?.find((item) => item.value === purchaseCode);

  const onChangeHandler = (e: string | null) => {
    selectedItem = options?.find((item) => item.value === e);
    onChange?.(e);
  };

  return (
    <Select
      styles={
        purchaseCode
          ? {
            input: {
              width: width,
              fontSize: "0.8em",
              paddingBottom: "0.5rem",
            },
          }
          : { input: { width: width } }
      }
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedItem?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedItem?.value}
            </div>
          )}
        </div>
      )}
      value={purchaseCode}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={selectOptions}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={PurchaseOrderInfo({
        item: selectedItem as PurchaseOrdersOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewPurchaseOrder({
        purchaseOrderCode: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const PurchaseOrderInfo = (params: {
  item?: PurchaseOrdersOption;
  onChange: (purchaseOrderCode: string | null) => void;
}) => {
  const { item, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return item?.value ? (
    <ItemInfoLabel>
      {/* ({item.value}) */}
      <IconX size="1rem" onClick={clearHandler} />
    </ItemInfoLabel>
  ) : null;
};

const AddNewPurchaseOrder = (params: {
  purchaseOrderCode: string;
  onChange: (purchaseOrderCode: string) => void;
}) => {
  const { purchaseOrderCode, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(
    (params: ScmApiPurchaseOrdersPostRequest) =>
      mutatePurchaseOrders.create(params).mutationFn(params as ScmApiPurchaseOrdersPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('purchaseOrders');
        closeModal(res.data)
        customAlert(
          `수주 등록에 성공하였습니다.`,
          "등록 성공",
          "green"
        )
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: "수주" }), "수주 생성 실패", 'red')
      }
    }
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    createMutate(
      {
        purchaseOrdersGetRequest: {
          partnerCode: values.partnerCode!,
          direction: "INBOUND",
          code: values.code!,
          orderDate: values.orderDate!,
          deliveryDeadline: values.deliveryDeadline!,
          userCode: values.userCode!,
          sourceLocationCode: values.sourceLocationCode!,
        }
      },
    )
  }

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <PurchaseOrderCreateForm code={purchaseOrderCode} autoCompleteOnSubmit={onCloseHandler} />,
      null, "신규 수주 등록", true).then(
        (result) => {
          onChange(result?.code);
        }
      );
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 수주({purchaseOrderCode}) 등록
    </Button>
  );
};
const ItemInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  // right: 30px;
  position: absolute;
`;
