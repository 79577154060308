import { equipments } from "@/api/equipment/useEquipmentQuery";
import { KPI } from "@/api/kpi/useKPIQuery";
import { columnlist } from "@/constants/columnlist";
import { useModal } from "@/features/modal/ModalStackManager";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute } from "@/utils/dateTimeUtil";
// import { AlignTextRight } from "@/utils/textRight";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Flex, Space, Text } from "@mantine/core";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const KPIRateOfOperation = Object.assign({}, {
    Header : Header,
    HeaderTitle : HeaderTitle,
    HeaderSubTitle : HeaderSubTitle,
    CustomTable : CustomTable,
})

export const KPIRateOfOperationPage = () => {

    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();
    
    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [equipment, setEquipment] = useState<string | null>('');
    const [averageRateOfOperation, setAverageRateOfOperation] = useState<string>('');
    const { openModal } = useModal();

    const { data } = useQuery({
        ...equipments.get({
            query : {
                $and : [
                    { code : equipment }
                ]
            }
        }),
        enabled : !!equipment
    })

    const equipmentData = data?.data?.rows && data?.data?.rows[0]

    const TableHeader = {
        machine: [ '설비명', '평균 설비가동률'],
    }

    const BodyData = {
        machine: [{ '설비명': equipmentData?.name ?? '-', '평균 설비가동률': averageRateOfOperation === 'NaN' ? '-' : averageRateOfOperation }],
    }

    const { data : rateOfOperationData } = useQuery({
        ...KPI.rateOfOperation({
            equipmentCode : equipment ?? '',
            scheduledAtFrom : stateDate[0]?.toISOString() ?? '',
            scheduledAtTo : stateDate[1]?.toISOString() ?? ''
        }),
        enabled : !!equipment
    })

    useEffect(() => {
        if(rateOfOperationData?.data){
            const transformedData = rateOfOperationData.data.map((rateOfOperation: any) => ({
                id : rateOfOperation.id,
                productionPlanId : rateOfOperation.productionPlanId,
                operationName : rateOfOperation.operationName,
                equipmentName : rateOfOperation.equipmentName,
                itemCode : rateOfOperation.itemCode,
                itemName : rateOfOperation.itemName,
                itemSpec : rateOfOperation.itemSpec,
                itemStandardUph : rateOfOperation.itemStandardUph,
                workerName : rateOfOperation.workerName,
                workStartTime : rateOfOperation.workStartTime === null ? '-' : DatetimeUtilMinute(rateOfOperation.workStartTime),
                workEndTime : rateOfOperation.workEndTime === null ? '-' : DatetimeUtilMinute(rateOfOperation.workEndTime),
                pauseDuration : rateOfOperation.pauseDuration,
                totalQuantity : rateOfOperation.totalQuantity,
                goodQuantity : rateOfOperation.goodQuantity,
                defectQuantity : rateOfOperation.defectQuantity,
                capacityUtilization : rateOfOperation.capacityUtilization,
            }));

            const totalCapacityUtilization = rateOfOperationData?.data?.reduce((sum: number, rateOfOperation: any) => sum + parseFloat(rateOfOperation.capacityUtilization), 0) ?? 0;
            const averageCapacityUtilization = (totalCapacityUtilization / rateOfOperationData.data.length).toFixed(2);
            setAverageRateOfOperation(averageCapacityUtilization);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    },[rateOfOperationData])

    return (
        <Flex gap="md" direction={'column'}>
            <KPIRateOfOperation.Header>
                <KPIRateOfOperation.HeaderTitle>설비가동률(P)</KPIRateOfOperation.HeaderTitle>
                <KPIRateOfOperation.HeaderSubTitle>설비가 주어진 조건 하에서 정상적으로 가동하였을 때 생산할 수 있는 최대 생산량애 대한 실제 생산량의 비율이다.</KPIRateOfOperation.HeaderSubTitle>
            </KPIRateOfOperation.Header>
            <Flex justify={'space-between'} align={'center'}>
                <Space miw={'22rem'} />
                <Flex align={'center'}>
                    <Flex align="center" gap="sm" onClick={() => {
                        openModal(<Calendar dates={stateDate} type="range" isExclude={true} />, null, '날짜 선택')
                            .then((value) => {
                                if (isCalendarDate(value)) {
                                    setStateDate(value);
                                }
                            });
                    }}>
                        <Text size="2rem" fw="bold" style={{ cursor: "pointer" }}>
                            {timeUtil(stateDate[0]?.toISOString() ?? '')} ~ {timeUtil(stateDate[1]?.toISOString() ?? '')}
                        </Text>
                        <ActionIcon>
                            <IconCalendar size="2rem" />
                        </ActionIcon>
                    </Flex>
                </Flex>
                <EquipmentAutoComplete
                    placeholder="설비를 선택해주세요"
                    w="22rem"
                    size="lg"
                    onChange={(value) => {setEquipment(value)}}
                />
            </Flex>
            
            <KPIRateOfOperation.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.rateOfoperationTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            // scrollEnd={(value) => {
            //     if(value){
            //         if(keyword !== undefined){
            //             if(searchPage.total_page > searchPage.current_page){
            //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
            //             }
            //         }else{
            //             if(page.total_page > page.current_page){
            //                 getPressDailyStatusApi(page.current_page+ 1)
            //             }
            //         }
            //     }
            // }}
            />
        </Flex>
    )
}