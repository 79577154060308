import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Flex, Tabs, Text, TextInput } from "@mantine/core";


import { mutateMolds } from "@/api/mold/useMoldQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiMoldsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  FormWrapper: FormWrapper,
  ButtonBox: FormButtonBox,
  Text: Text,
  Flex: Flex,
  Tab: Tabs,
});

interface MoldCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const MoldCreateForm = (params : MoldCreateFormProps) => {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiMoldsPostRequest) =>
      mutateMolds.create(params).mutationFn(params as MasterApiMoldsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('molds')
      }
    }
  );

  const form = useForm({
    initialValues: {
      code: code ?? undefined,
      siteCode: undefined,
      capacity: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          moldsGetRequest: {
            code: code ?? form.values.code!,
            siteCode: form.values.siteCode!,
            capacity: form.values.capacity!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "금형 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "금형 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '금형코드' }), '금형 생성 실패', 'red')
          }
        }
      );
    } catch (e) {
      customAlert(
        "금형 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <>
      <Create.FormWrapper>
        <Create.Input
          label="금형코드"
          withAsterisk
          {...form.getInputProps("code")}
        />
        <SitesAutoComplete
          width="100%"
          label="사업장"
          {...form.getInputProps("siteCode")}
        />
        <Create.Input label="금형용량" {...form.getInputProps("capacity")} />
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "mold",
              values: form.values,
            })}
            onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          >
            금형 생성
          </Create.Button>
        </Create.ButtonBox>
      </Create.FormWrapper>
    </>
  );
};
