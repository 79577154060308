import { defects } from '@/api/defect/useDefectsQuery';
import { useModal } from '@/features/modal/ModalStackManager';
import styled from '@emotion/styled';
import { Button, TransferList, TransferListData, TransferListItem } from '@mantine/core';
import { DefectsGet200ResponseRowsInner } from '@sizlcorp/sizl-api-document/dist/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

// DefectTypeItem 인터페이스 정의
export interface DefectTypeItem extends TransferListItem {
    operationCode?: string; // optional로 설정
}

// 객체 깊은 비교 함수 (deepEqual)
const deepEqual = (obj1: any, obj2: any): boolean => {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return obj1 === obj2;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }
    return true;
};

// 배열 비교 함수 (배열 내 객체들을 비교)
const isSameData = (
    data1: [DefectTypeItem[], DefectTypeItem[]],
    data2: [DefectTypeItem[], DefectTypeItem[]]
): boolean => {
    return (
        data1.length === data2.length &&
        data1.every((list, index) =>
            list.every((item, itemIndex) => deepEqual(item, data2[index][itemIndex]))
        )
    );
};

export const DefectTypeSet = () => {
    const { closeModal } = useModal();
    const storageValue = window.localStorage.getItem('defectTypes');
    const parsedStorageValue: [DefectTypeItem[], DefectTypeItem[]] | null =
        storageValue ? JSON.parse(storageValue) : null;

    const { queryKey, queryFn } = defects.findSelect({});
    const { data: defectData, isLoading, isError, error } = useQuery(queryKey, queryFn, {
        select: (data: DefectsGet200ResponseRowsInner[] | undefined) =>
            data?.map(defect => ({
                value: defect.code!,
                label: defect.name!,
                operationCode: defect.operationCode ?? undefined,
            })) ?? [],
        onError: (err) => console.error('Error fetching defects:', err),
    });

    const formattedDefectData = useMemo<[DefectTypeItem[], DefectTypeItem[]]>(() => {
        const allDefects = defectData?.map(defect => ({
            value: defect.value!,
            label: defect.label!,
            operationCode: defect.operationCode ?? undefined, // 임시 operationCode 추가
        })) ?? [];
        return [[], allDefects];
    }, [defectData]);

    const needsMigration = useCallback((): boolean => {
        if (!parsedStorageValue) return false;
        return parsedStorageValue.flat().some(defect => !('operationCode' in defect));
    }, [parsedStorageValue]);

    const migrateStorageData = useCallback((): [DefectTypeItem[], DefectTypeItem[]] | null => {
        if (!parsedStorageValue || !defectData) return null;

        const migrateData = (list: DefectTypeItem[]): DefectTypeItem[] =>
            list.map(storedDefect => {
                const matchingDefect = defectData.find(defect => defect.value === storedDefect.value);
                return {
                    ...storedDefect,
                    operationCode: matchingDefect?.operationCode || undefined, // 임시 값
                };
            });

        const [storedDefectTypes, storedUseableDefectTypes] = parsedStorageValue;
        return [migrateData(storedDefectTypes), migrateData(storedUseableDefectTypes)];
    }, [parsedStorageValue, defectData]);

    const [fullData, setFullData] = useState<[DefectTypeItem[], DefectTypeItem[]]>([[], []]);
    const [uiData, setUiData] = useState<TransferListData>([[], []]);

    useEffect(() => {
        // console.log('defectData:', defectData);
        // console.log('formattedDefectData:', formattedDefectData);
        // console.log('parsedStorageValue:', parsedStorageValue);

        if (needsMigration() && defectData && !isLoading) {
            const migratedData = migrateStorageData();
            console.log('migratedData:', migratedData);

            if (migratedData && !isSameData(migratedData, fullData)) {
                setFullData(migratedData);
                setUiData([
                    migratedData[0].map(({ value, label }) => ({ value, label })),
                    migratedData[1].map(({ value, label }) => ({ value, label })),
                ]);
                window.localStorage.setItem('defectTypes', JSON.stringify(migratedData));
            }
        }
        // API 데이터와 로컬 스토리지 데이터를 비교하여 다른 경우에만 업데이트
        else if (parsedStorageValue && defectData && !isLoading) {
            const flatStoredData = parsedStorageValue.flat(); // 로컬 스토리지 데이터를 flat하게 변환

            // 로컬 데이터와 API 데이터의 일치 여부 확인
            const allItemsMatch1 = flatStoredData.every(storedItem =>
                defectData.some(apiItem =>
                    apiItem.value === storedItem.value &&
                    apiItem.label === storedItem.label
                )
            );

            // API 데이터와 로컬 데이터의 일치 여부 확인
            const allItemsMatch2 = defectData.every(storedItem =>
                flatStoredData.some(apiItem =>
                    apiItem.value === storedItem.value &&
                    apiItem.label === storedItem.label
                )
            );

            if (allItemsMatch1 && allItemsMatch2) {
                console.log('로컬 스토리지와 API 데이터가 일치합니다. 그대로 상태에 반영합니다.');

                const newUiData: TransferListData = [
                    parsedStorageValue[0].map(({ value, label }) => ({ value, label })),
                    parsedStorageValue[1].map(({ value, label }) => ({ value, label })),
                ];

                setFullData(parsedStorageValue);
                setUiData(newUiData);
            } else {
                console.log('로컬 스토리지와 API 데이터가 일치하지 않습니다. 업데이트 중...');

                // API 데이터에만 있는 항목 추가
                const addMissingData = defectData.filter(apiItem =>
                    !flatStoredData.some(storedItem => storedItem.value === apiItem.value)
                );

                // 로컬 스토리지에만 있는 항목 제거
                const cleanedStoredData = flatStoredData.filter(storedItem =>
                    defectData.some(apiItem => apiItem.value === storedItem.value)
                );

                // console.log('추가할 데이터:', addMissingData);
                // console.log('제거할 데이터:', flatStoredData.filter(item => !cleanedStoredData.includes(item)));

                // "불량 유형" 측 리스트에 새로운 데이터를 병합
                const newDefectTypes = [
                    ...cleanedStoredData.filter(item => !parsedStorageValue[1].includes(item)), // 사용되지 않는 항목만 포함
                    ...addMissingData.map(apiItem => ({
                        value: apiItem.value,
                        label: apiItem.label,
                        operationCode: apiItem.operationCode ?? undefined,
                    })),
                ];

                // "사용할 불량 유형" 측 데이터는 기존 그대로 유지
                const newUseableDefectTypes = parsedStorageValue[1].filter(useableItem =>
                    defectData.some(apiItem => apiItem.value === useableItem.value)
                );

                const newFullData: [DefectTypeItem[], DefectTypeItem[]] = [
                    newDefectTypes,
                    newUseableDefectTypes,
                ];

                const newUiData: TransferListData = [
                    newDefectTypes.map(({ value, label }) => ({ value, label })),
                    newUseableDefectTypes.map(({ value, label }) => ({ value, label })),
                ];

                // 상태 업데이트 및 로컬 스토리지에 반영
                setFullData(newFullData);
                setUiData(newUiData);
                window.localStorage.setItem('defectTypes', JSON.stringify(newFullData));
            }
        }
        // 로컬 스토리지에 데이터가 없을 때 API 데이터를 사용
        else if (!parsedStorageValue && defectData && !isLoading) {
            if (!isSameData(formattedDefectData, fullData)) {
                setFullData(formattedDefectData);
                setUiData([
                    [],
                    formattedDefectData[1].map(({ value, label }) => ({ value, label })),
                ]);
                window.localStorage.setItem('defectTypes', JSON.stringify(formattedDefectData));
            }
        }
    }, [isLoading]);

    const handleDefectSave = () => {
        window.localStorage.setItem('defectTypes', JSON.stringify(fullData));
        closeModal(fullData);
    };

    return (
        <DefectTypeContainer>
            <TransferList
                value={uiData}
                onChange={(newValue) => {
                    const newFullData: [DefectTypeItem[], DefectTypeItem[]] = [
                        newValue[0].map(item => ({
                            ...item,
                            operationCode: fullData[0].find(d => d.value === item.value)?.operationCode || undefined,
                        })),
                        newValue[1].map(item => ({
                            ...item,
                            operationCode: fullData[1].find(d => d.value === item.value)?.operationCode || undefined,
                        })),
                    ];
                    console.log(newFullData, fullData)
                    if (JSON.stringify(newFullData) !== JSON.stringify(fullData)) {
                        setFullData(newFullData);
                        setUiData(newValue);
                    }
                }}
                searchPlaceholder="Search..."
                nothingFound="Nothing here"
                titles={['불량 유형', '사용할 불량 유형']}
                breakpoint="sm"
            />
            <Button variant="outline" onClick={handleDefectSave}>저장</Button>
        </DefectTypeContainer>
    );
};

const DefectTypeContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;