import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Button, Group, Radio, TextInput } from "@mantine/core";

import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";

import { mutateLocations } from "@/api/locations/useLocationsQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { useForm } from "@mantine/form";
import { MasterApiLocationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Select: FormSelect,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

interface LocationCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export const LocationCreateForm = (params : LocationCreateFormProps) => {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiLocationsPostRequest) =>
      mutateLocations.create(params).mutationFn(params as MasterApiLocationsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations')
      }
    }
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  const form = useForm({
    initialValues: {
      code: undefined,
      name: name ?? undefined,
      siteCode: undefined,
      isAvailable: undefined
    },
  });

  const onSubmit = async () => {
    try {
      createMutate(
        {
          locationsGetRequest: {
            code: form.values.code!,
            name: name ?? form.values.name!,
            siteCode: form.values.siteCode!,
            isAvailable: form.values.isAvailable === 'true' ? true : false
          },
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "로케이션 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "로케이션 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '로케이션코드' }), '로케이션 생성 실패', 'red')
          }
        }
      );
    } catch (e) {
      customAlert(
        "로케이션 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <>
      <Create.Wrapper>
        <Create.Input
          label="로케이션코드"
          withAsterisk
          {...form.getInputProps("code")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const regex = /^[A-Za-z0-9_]*$/;
            if (regex.test(e.target.value)) {
              form.getInputProps("code").onChange(e);
              setErrorMessage(""); // 유효한 입력인 경우 에러 메시지 제거
            } else {
              setErrorMessage("한글은 입력할 수 없습니다."); // 유효하지 않은 입력인 경우 에러 메시지 설정
            }
          }}
          error={errorMessage ? errorMessage : ""}
        />
        <Create.Input
          label="로케이션이름"
          withAsterisk
          {...form.getInputProps("name")}
        />
        <SitesAutoComplete
          width="100%"
          label="사업장코드"
          withAsterisk
          {...form.getInputProps("siteCode")}
        />
        {
          customFunctions.ADD_DEFECT_LOCATION &&
          <Radio.Group
            {...form.getInputProps("isAvailable")}
            defaultValue="true"
            name="가용 창고 여부"
            label="가용 창고 여부"
            withAsterisk
          >
            <Group mt="xs">
              <Radio value="true" label="가용" />
              <Radio value="false" label="비가용" />
            </Group>
          </Radio.Group>
        }
        <Create.ButtonBox>
          <Create.Button color="gray" onClick={closeModal}>
            취소
          </Create.Button>
          <Create.Button
            disabled={validateFormValues({
              pageType: "location",
              values: form.values,
            })}
            onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
          >
            로케이션 생성
          </Create.Button>
        </Create.ButtonBox>
      </Create.Wrapper>
    </>
  );
};
