import { items } from "@/api/items/useItemQuery";
import { KPI } from "@/api/kpi/useKPIQuery";
import { columnlist } from "@/constants/columnlist";
import { useModal } from "@/features/modal/ModalStackManager";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { GridTable } from "@/features/ui/PMS/GridTable";
import CustomTable from "@/features/ui/table/CustomTable";
import { DatetimeUtilMinute, getEndOfDate, getStartOfDate } from "@/utils/dateTimeUtil";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Flex, Space, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const KPIUPH = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    CustomTable: CustomTable,
})

export const KPIUPHPage = () => {

    const today = dayjs().subtract(1, 'day').startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set());
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [item, setItem] = useState<string | null>('');
    const [averageUPH, setAverageUPH] = useState<string>('');
    const { openModal } = useModal();

    const { data } = useQuery({
        ...items.get({
            query: {
                $and: [
                    { code: item }
                ]
            }
        }),
        enabled: !!item
    })

    const itemData = data?.data?.rows && data?.data?.rows[0]

    const { data: UPHData } = useQuery({
        ...KPI.UPH({
            itemCode: item ?? '',
            scheduledAtFrom: getStartOfDate(stateDate[0]),
            scheduledAtTo: getEndOfDate(stateDate[1])
        }),
        enabled: !!item
    })

    useEffect(() => {
        if (UPHData?.data) {
            const transformedData = UPHData.data.map((uph: any) => ({
                id: uph.id,
                productionPlanId: uph.productionPlanId,
                scheduledAt: uph.scheduledAt,
                operationName: uph.operationName,
                equipmentName: uph.equipmentName,
                itemCode: uph.itemCode,
                itemSpec: uph.itemSpec,
                workerName: uph.workerName,
                workStartTime: DatetimeUtilMinute(uph.workStartTime),
                workEndTime: DatetimeUtilMinute(uph.workEndTime),
                pauseDuration: uph.pauseDuration,
                totalQuantity: `${uph.totalQuantity} ${itemData?.unitText}`,
                goodQuantity: `${uph.goodQuantity} ${itemData?.unitText}`,
                defectQuantity: `${uph.defectQuantity} ${itemData?.unitText}`,
                uph: uph.uph,
            }));

            const totalUPH = UPHData?.data?.reduce((sum: number, uph: any) => sum + parseFloat(uph.uph), 0) ?? 0;
            const averageUPH = (totalUPH / UPHData.data.length).toFixed(2);

            setAverageUPH(averageUPH);
            setContent(transformedData);
        } else {
            setContent([]);
        }
    }, [UPHData])

    const TableHeader = {
        machine: ['품목코드', '품명', '규격', '평균 UPH'],
    }

    const BodyData = {
        machine: [{ '품목코드': itemData?.code ?? '-', '품명': itemData?.name, '규격': itemData?.spec ?? '-', '평균 UPH': averageUPH === "NaN" ? '0' : averageUPH }],
    }

    return (
        <Flex gap="md" direction={'column'}>
            <KPIUPH.Header>
                <KPIUPH.HeaderTitle>UPH(P)</KPIUPH.HeaderTitle>
                <KPIUPH.HeaderSubTitle>시간당 생산량</KPIUPH.HeaderSubTitle>
            </KPIUPH.Header>
            <Flex justify={'space-between'} align={'center'}>
                <Space miw={'22rem'} />
                <Flex align={'center'}>
                    <Flex align="center" gap="sm" onClick={() => {
                        openModal(<Calendar dates={stateDate} type="range" isExclude={true} />, null, '날짜 선택')
                            .then((value) => {
                                if (isCalendarDate(value)) {
                                    setStateDate(value);
                                }
                            });
                    }}>
                        <Text size="2rem" fw="bold" style={{ cursor: "pointer" }}>
                            {timeUtil(stateDate[0]?.toISOString() ?? '')} ~ {timeUtil(stateDate[1]?.toISOString() ?? '')}
                        </Text>
                        <ActionIcon>
                            <IconCalendar size="2rem" />
                        </ActionIcon>
                    </Flex>
                </Flex>
                <ItemAutoComplete
                    placeholder="품목을 선택해주세요"
                    w="22rem"
                    size="lg"
                    getItemType={[ItemsGet200ResponseRowsInnerItemTypeEnum.PRODUCT, ItemsGet200ResponseRowsInnerItemTypeEnum.SEMI_PRODUCT]}
                    onChange={(value) => setItem(value)}
                />
            </Flex>

            <KPIUPH.CustomTable
                headers={TableHeader.machine}
                data={BodyData.machine}
            />
            <GridTable
                headerList={[
                    ...columnlist.uphTable
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={750}
                resizable={true}
            // scrollEnd={(value) => {
            //     if(value){
            //         if(keyword !== undefined){
            //             if(searchPage.total_page > searchPage.current_page){
            //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
            //             }
            //         }else{
            //             if(page.total_page > page.current_page){
            //                 getPressDailyStatusApi(page.current_page+ 1)
            //             }
            //         }
            //     }
            // }}
            />
        </Flex>
    )
}