import { defectStatistics } from "@/api/quality/useGetQuality";
import { useModal } from "@/features/modal/ModalStackManager";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { DefectChart } from "@/features/ui/PMS/chart/defectChart";
import PieApexChart from "@/features/ui/PMS/chart/pieChart";
import CustomTable from "@/features/ui/table/CustomTable";
import timeUtil from "@/utils/timeUtil";
import { ActionIcon, Box, Flex, Text } from "@mantine/core";
import { QualityDefectStatisticsGet200ResponseInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

const defectCodeMap: { [key: string]: string } = {
    "절곡/포밍": "bending",
    "휨/물결": "bowing",
    "절단위치(컷팅)": "cutting",
    "기타": "etc",
    "편심/PITCH": "pitch",
    "파손": "BREAK",
    "색상": "COLOR",
    "원자재녹": "rust",
    "용접/원자재": "welding",
    "스크래치" : "SCRATCH"
};

export const DefectStatisticsPage = () => {
    const today = dayjs().startOf('day').toDate();
    const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([oneWeekAgo, today]);
    const [equipment, setEquipment] = useState<string | null>('');
    const [defectCode, setDefectCode] = useState<string | null>('');
    const [bodyData, setBodyData] = useState<{ defect: any[] }>({ defect: [] });
    const { t } = useTranslation();

    const { data } = useQuery({
        ...defectStatistics.defectStatistics({
            equipmentCode: equipment ?? '',
            scheduledAtFrom: stateDate[0]?.toISOString() ?? '',
            scheduledAtTo: stateDate[1]?.toISOString() ?? ''
        }),
        enabled: !!equipment
    });

    const names = data?.data?.map((item: any) => t(item.defectCode));
    const workCount = data?.data?.map((item: any) => item.workCount);
    const totalCount = data?.data?.map((item: any) => item.totalDefects);

    useEffect(() => {
        if (equipment !== '') {
            if (data?.data) {
                setBodyData({
                    defect: data.data.map((defect: QualityDefectStatisticsGet200ResponseInner) => ({
                        '유형': t(defect.defectCode ?? ''),
                        '건수': defect.workCount,
                        '수량': defect.totalDefects
                    }))
                });
            }
        }
    }, [data, equipment]);


    const totalCounts = bodyData.defect.reduce(
        (totals, row) => {
            totals['건수'] += Number(row['건수']);
            totals['수량'] += Number(row['수량']);
            return totals;
        },
        { '건수': 0, '수량': 0 }
    );

    const { data: dateData } = useQuery({
        ...defectStatistics.defectStatisticsDetail({
            equipmentCode: equipment ?? '',
            scheduledAtFrom: stateDate[0]?.toISOString() ?? '',
            scheduledAtTo: stateDate[1]?.toISOString() ?? '',
            defectCode: defectCode ?? ''
        }),
        enabled: !!defectCode
    })

    const Header = {
        defect: ['유형', '건수', '수량'],
        total : ['합계', '건수', '수량']
    };

    const { openModal } = useModal();

    return (
        <Flex direction={'column'} gap="md">
            <Flex>
                <Text size="lg">불량유형 통계</Text>
            </Flex>
            <Flex justify={'center'}>
                <Flex
                    gap={'sm'}
                    align={'center'}
                    onClick={() => {
                        openModal(<Calendar dates={stateDate} type="range" isExclude={true} />, null, '날짜 선택')
                            .then((value) => {
                                if (isCalendarDate(value)) {
                                    setStateDate(value);
                                }
                            });
                    }}>
                    <Text
                        size="2rem"
                        fw="bold"
                        style={{ cursor: "pointer" }}
                    >{timeUtil(stateDate[0]?.toISOString() ?? '')} ~ {timeUtil(stateDate[1]?.toISOString() ?? '')}</Text>
                    <ActionIcon><IconCalendar size="2rem" /></ActionIcon>
                </Flex>
            </Flex>

            <Flex direction={'column'}>
                <Text size="sm">기계</Text>
                <EquipmentAutoComplete
                    placeholder="기계를 선택하세요"
                    onChange={(value) => setEquipment(value)}
                />
            </Flex>
            <Flex direction={'row'} gap="1rem">
                <Flex w="40%" h={'18rem'} direction={'column'} style={{ maxHeight: '18rem', overflowY: 'auto' }}>
                    <CustomTable
                        headers={Header.defect}
                        data={bodyData.defect}
                        onRowClick={(row) => {
                            const selectedDefectCode = defectCodeMap[row['유형']];
                            if (selectedDefectCode) {
                                setDefectCode(selectedDefectCode);
                            } else {
                                console.error('유효하지 않은 유형입니다:', row['유형']);
                            }
                        }}
                    />
                </Flex>

                {/* TO-BE : 파이차트로 건수별 / 수량별 만들기 */}
                <Flex justify={'center'}>
                    <Text size="xl">건수별</Text>
                    <Box w={"22rem"} h={"22rem"}>
                        <PieApexChart names={names} count={workCount} />
                    </Box>
                    <Text size="xl">수량별</Text>
                    <Box w={"22rem"} h={"22rem"}>
                        <PieApexChart names={names} count={totalCount} />
                    </Box>
                </Flex>
            </Flex>
            <Flex w={'40%'}>
                <CustomTable 
                    headers={Header.total}
                    data={[
                        { '합계': '총 합계', '건수': totalCounts['건수'], '수량': totalCounts['수량'] }
                    ]}
                />
            </Flex>
            <Flex w="100%">
                <DefectChart data={dateData} />
            </Flex>
        </Flex>
    );
};
