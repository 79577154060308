import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { PartnerAutoComplete } from "@/features/ui/autoComplete/partner/partner-autoComplete";
import { PurchaseOrderAutoComplete } from "@/features/ui/autoComplete/purchaseOrder/purchaseOrders-autoComplete";
import { UserAutoComplete } from "@/features/ui/autoComplete/user/user-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";

const Create = Object.assign(FormMain, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    Text: Text,
    Flex: Flex,
    DateInput: DateInput
});

interface OrderUpdateFormProps {
    orderId? : number;
    formatterProps : AuthSignupPost201ResponseEquipmentPurchaseOrderItem
}

export const OrderItemsUpdateForm = (
    props: OrderUpdateFormProps
) => {
    const { orderId, formatterProps } = props;
    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: createMutate } = useMutation(
        (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest) =>
            mutatePurchaseOrderItems.update(params).mutationFn(params as ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('purchaseOrderItems');
            }
        }
    );

    const form = useForm({
        initialValues: {
            partnerCode : formatterProps.partnerCode,
            itemCode : formatterProps.itemCode,
            quantity : formatterProps.quantity,
            orderDate : formatterProps.orderDate ? new Date(formatterProps.orderDate) : undefined,
            deliveryDeadline : formatterProps.deliveryDeadline ? new Date(formatterProps.deliveryDeadline) : undefined,
            userCode : formatterProps.userCode,
            targetLocationCode : formatterProps.targetLocationCode,
        }
    })

    const onSubmit = async () => {
        try {
            createMutate(
                {
                    purchaseOrderItemId : formatterProps.id ?? 0,
                    purchaseOrderItemsGetRequest : {
                        purchaseOrderId : formatterProps.purchaseOrderId,
                        partnerCode : form.values.partnerCode!,
                        direction : "OUTBOUND",
                        itemCode : form.values.itemCode!,
                        quantity : form.values.quantity!,
                        orderDate : form.values.orderDate?.toDateString()!,
                        deliveryDeadline : form.values.deliveryDeadline!?.toDateString(),
                        userCode : form.values.userCode!,
                        targetLocationCode : form.values.targetLocationCode!,
                    }
                },
                {
                    onSuccess: (res) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "발주 항목 수정에 성공하였습니다.",
                                "수정 성공",
                                "green"
                            )
                        }
                    },
                    onError: (error: any) => {
                        customAlert(handleErrorResponse({ error, errorContext: '발주 항목 ' }), '발주 항목  수정 실패', 'red')
                    }
                }
            )
        } catch (e) {
            customAlert(
                "발주 항목 수정 실패하였습니다.",
                "수정 실패",
                "red"
            )
        }
    };

    return (
        <>
            <Create.Wrapper>
                <PurchaseOrderAutoComplete 
                    label="발주 코드"
                    placeholder="발주 코드 입력"
                    {...form.getInputProps("purchaseOrderId")}
                    defaultValue={formatterProps.partnerCode} // purchaseOrderCode로 변경하기
                    error={form.isDirty("purchaseOrderId") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("purchaseOrderId") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("purchaseOrderId") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                    direction="OUTBOUND"
                />
                <PartnerAutoComplete
                    label="협력사"
                    placeholder="협력사"
                    defaultValue={formatterProps.partnerCode}
                    {...form.getInputProps("partnerCode")}
                    withAsterisk
                    error={form.isDirty("partnerCode") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("partnerCode") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("partnerCode") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <ItemAutoComplete
                    label="품목 코드"
                    placeholder="품목 코드 입력"
                    {...form.getInputProps("itemCode")}
                    defaultValue={formatterProps.itemCode}
                    error={form.isDirty("itemCode") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("itemCode") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("itemCode") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Create.Input
                    label="수량"
                    placeholder="수량"
                    {...form.getInputProps("quantity")}
                    defaultValue={formatterProps.quantity}
                    error={form.isDirty("quantity") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("quantity") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("quantity") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <UserAutoComplete 
                    label="담당자"
                    placeholder="담당자 입력"
                    {...form.getInputProps("userCode")}
                    defaultValue={formatterProps.userCode}
                    error={form.isDirty("userCode") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("userCode") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("userCode") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <LocationAutoComplete 
                    label="입고 로케이션"
                    placeholder="입고 로케이션"
                    {...form.getInputProps("targetLocationCode")}
                    defaultValue={formatterProps.targetLocationCode}
                    error={form.isDirty("targetLocationCode") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("targetLocationCode") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("targetLocationCode") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="발주일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("orderDate")}
                    error={form.isDirty("orderDate") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("orderDate") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("orderDate") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Create.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="납기일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("deliveryDeadline")}
                    error={form.isDirty("deliveryDeadline") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("deliveryDeadline") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("deliveryDeadline") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>취소</Create.Button>
                    <Create.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrderItems",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        발주 항목 수정
                    </Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper>
        </>
    )
};