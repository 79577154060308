import { useQuery } from "react-query";

import { MasterInstance } from "@/instance/axios";
import { ItemsGet200ResponseRowsInner, ItemsGet200ResponseRowsInnerItemTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

export type ItemOption = {
  value: string;
  label: string;
  group: string;
};
const getAutoCompleteKeyword = (
  keyword: string,
  getItemType?: ItemsGet200ResponseRowsInnerItemTypeEnum[]
) =>
  MasterInstance.itemsFindPost(
    {
      search: keyword,
      searchFields: ["name", "code"],
      populate: ["itemCategory"],
    },
    {
      params: {
        ...(!keyword && { limit: 50 }),
        query: {
          itemType: getItemType
        },
      },
    }
  ).then((res: AxiosResponse<ItemsGet200ResponseRowsInner[]>) => res.data);

export const useItemsAutoComplete = (
  focus = false,
  keyword = "",
  getItemType?: ItemsGet200ResponseRowsInnerItemTypeEnum[],
  options?: {
    onSuccess: (data: ItemOption[]) => void;
  }
) => {
  const { t } = useTranslation();

  return useQuery(
    ["items-get-autocomplete-keyword", keyword],
    () => getAutoCompleteKeyword(keyword, getItemType),
    {
      enabled: focus,
      select: (data: ItemsGet200ResponseRowsInner[]) =>
        data?.map((item) => {
          return {
            value: item.code,
            label: item.name,
            spec: item.spec,
            group: t(item.itemType),
            // group: String(item.itemCategoryId),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getItemByCode = (code: string | null) =>
  MasterInstance.itemsFindPost(
    {
      ...(code && { search: code }),
      searchFields: ["code"],
    },
    {
      ...(!code && { limit: 50 }),
      params: {
        query: {},
      },
    }
  ).then((res: AxiosResponse<ItemsGet200ResponseRowsInner[]>) => res.data);

export const useItemsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: ItemOption[]) => void;
  }
) => {
  return useQuery(["items-get-code", code], () => getItemByCode(code), {
    enabled: enabled ?? false,
    select: (data: ItemsGet200ResponseRowsInner[]) =>
      data?.map((item) => {
        return {
          value: item.code,
          label: item.name,
          group: String(item.itemCategoryName),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
