import { DOWN_TIME_REASONS, downtimeReasons, mutateDownTimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { DowntimeReasonsCreateForm } from "@/features/downtimeReasons/form/CreateForm";
import { DowntimeReasonsTable } from "@/features/downtimeReasons/table";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from "@/features/ui/alert/alert";
import DragAndDropList, { DragAndDropItemDataType } from "@/features/ui/dragAndDropList/DragAndDropList";
import { usePub } from "@/hooks";
import { Button, Flex } from "@mantine/core";
import { DefaultApiDowntimeReasonsUpdateSeqPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconReorder, IconTrash } from "@tabler/icons-react";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const DowntimeReasons = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  DragAndDropList: DragAndDropList,
  ActionButton: Button,
  CreateForm: DowntimeReasonsCreateForm,
});

export const DowntimeReasonsPage = () => {

  const { openModal, closeModal } = useModal();

  const { data: ItemCategories } = useQuery(downtimeReasons.find({
    query: {
      $and: []
    },
    sort: "seq"
  }));

  const queryClient = useQueryClient();

  const { mutate: updateSeqMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsUpdateSeqPostRequest) =>
      mutateDownTimeReasons.updateSeq(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customAlert(
          "비가동사유 순서가 등록되었습니다.",
          "등록 성공",
          "green"
        );
        queryClient.invalidateQueries(DOWN_TIME_REASONS)
        closeModal({});
      },
      onError: (error: AxiosError<Error>) => {
        customAlert(
          error.response?.data?.message ?? "비가동 순서 등록에 실패하였습니다.",
          "등록 실패",
          "red"
        )
        closeModal({});
      }
    }
  );

  const dragAndDropItems = ItemCategories?.data.map((item) => {
    return {
      id: item.code as string,
      content: `${item.name}( ${item.code} )`,
    }
  })

  const maxSeq = ItemCategories?.data?.length
    ? ItemCategories?.data?.[ItemCategories?.data.length - 1]?.seq as number + 1
    : 0;

  const onSubmit = (items: DragAndDropItemDataType[]) => {
    updateSeqMutate({
      equipmentsUpdateSeqPostRequest: {
        code: items.map((item) => item.id),
        seq: items.map((_, index) => index + 1)
      }
    })
  }


  const publish = usePub();

  return (
    <DowntimeReasons.Wrapper>
      <DowntimeReasons.ActionHeader>
        <Flex gap={10}>
          <DowntimeReasons.ActionButton
            leftIcon={<IconPlus />}
            onClick={() =>
              openModal(
                <DowntimeReasons.CreateForm maxSeq={maxSeq} />,
                null,
                "비가동사유 추가",
                true
              )
            }
          >
            추가
          </DowntimeReasons.ActionButton>
          <DowntimeReasons.ActionButton
            leftIcon={<IconReorder />}
            color="cyan"
            onClick={() =>
              openModal(
                <DowntimeReasons.DragAndDropList
                  data={dragAndDropItems}
                  onSubmit={onSubmit}
                />,
                null,
                "비가동사유 순서설정",
                true
              )
            }
          >
            순서
          </DowntimeReasons.ActionButton>
        </Flex>
        <DowntimeReasons.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </DowntimeReasons.ActionButton>
      </DowntimeReasons.ActionHeader>
      <DowntimeReasons.Header>
        <DowntimeReasons.HeaderTitle>비가동사유</DowntimeReasons.HeaderTitle>
        <DowntimeReasons.HeaderSubTitle>
          우리 회사에서 다루는 비가동사유에 대해 조회할 수 있는 페이지 입니다.
        </DowntimeReasons.HeaderSubTitle>
      </DowntimeReasons.Header>
      <DowntimeReasonsTable />
    </DowntimeReasons.Wrapper>
  );
};
