import { mutateUsers } from "@/api/users/useUsersQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { DepartmentAutoComplete } from "@/features/ui/autoComplete/department/department-autoComplete";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiUsersUserIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

export function UsersUpdateForm(params: { formatterProps: any }) {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiUsersUserIdPutRequest) =>
      mutateUsers.update(params).mutationFn(params as MasterApiUsersUserIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    }
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      username: formatterProps.username,
      password: formatterProps.password,
      equipmentCode: formatterProps.equipmentCode,
      printerCode: formatterProps.printerCode,
      locationCode: formatterProps.locationCode,
      departmentCode: formatterProps.departmentCode,
    },
  });

  const onSubmit = () => {
    try {
      updateMutate(
        {
          userId: formatterProps.id as number,
          usersUserIdDeleteRequest: {
            code: form.values.code!,
            name: form.values.name!,
            username: form.values.username!,
            password: form.values.password!,
            equipmentCode: form.values.equipmentCode!,
            printerCode: form.values.printerCode!,
            locationCode: form.values.locationCode!,
            departmentCode: form.values.departmentCode!
          },
        },
        {
          onSuccess: () => {
            alert("사용자 수정 성공");
            closeModal(formatterProps);
          },
        }
      );
    } catch (e) {
      console.log(e);
      alert("실패");
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label="사용자코드"
            withAsterisk
            {...form.getInputProps("code")}
            defaultValue={formatterProps?.code}
            error={form.isDirty("code") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            label="사용자명"
            withAsterisk
            {...form.getInputProps("name")}
            defaultValue={formatterProps?.name}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            label="로그인아이디"
            withAsterisk
            {...form.getInputProps("username")}
            defaultValue={formatterProps?.username}
            error={form.isDirty("username") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("username")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("username")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            // type="number"
            label="비밀번호"
            // placeholder="password"
            {...form.getInputProps("password")}
            defaultValue={formatterProps?.password}
            error={form.isDirty("password") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("password")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("password")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <EquipmentAutoComplete
            label="설비코드"
            {...form.getInputProps("equipmentCode")}
            defaultValue={formatterProps?.equipmentCode}
            error={form.isDirty("equipmentCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("equipmentCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("equipmentCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <LocationAutoComplete
            label="로케이션코드"
            {...form.getInputProps("locationCode")}
            defaultValue={formatterProps?.locationCode}
            error={form.isDirty("locationCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("locationCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("locationCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <DepartmentAutoComplete
            label="부서코드"
            {...form.getInputProps("departmentCode")}
            defaultValue={formatterProps?.departmentCode}
            error={form.isDirty("departmentCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("departmentCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("departmentCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            label="프린터 코드"
            {...form.getInputProps("printerCode")}
            defaultValue={formatterProps?.printerCode}
            error={form.isDirty("printerCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("printerCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("printerCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "users",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              사용자 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
