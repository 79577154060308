import { wmsLog } from "@/api/delivery/useDeliveryQuery";
import { preWarehouseListHeader } from "@/constants/columnHeader";
import { columnlist } from "@/constants/columnlist";
import { Header } from "@/features/standard/Header";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { GridTable } from "@/features/ui/PMS/GridTable";
import timeUtil from "@/utils/timeUtil";
import { Flex, Pagination } from "@mantine/core";
import { useEffect, useState } from "react";
import { SelectColumn } from "react-data-grid";
import { useQuery } from "react-query";

const PreWareHouseList = Object.assign({}, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
});

export const PreWareHouseListPage = () => {

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set())
    const [page, setPage] = useState<number>();
    const [search, setSearch] = useState<SearchProps>({
        search: "",
        searchFields: [],
        pageSize: "10"
    });

    const { data: wmsLogData, refetch } = useQuery({
        ...wmsLog.get({
            sort: '-createdAt',
            page: page,
            pageSize: Number(search.pageSize),
            search: search.search,
            searchFields: search.searchFields.length
                ? search.searchFields
                : preWarehouseListHeader.map((item) => item.value),
            query: {
                $and: [
                    { 'actionType': 'INCOMING' },
                ]
            }
        })
    })

    useEffect(() => {
        setPage(1);
        refetch();
    }, [search.search, search.searchFields.length, search.pageSize, refetch]);

    useEffect(() => {
        if (wmsLogData?.data) {
            const transformedData = wmsLogData.data.rows?.map((wms: any) => ({
                itemCode: wms.actionParam?.params?.itemCode,
                lotId: wms.actionParam?.params?.lotId,
                lotName: wms.actionParam?.params?.lotInfo.name,
                createdAt: timeUtil(wms.createdAt),
            }));
            setContent(transformedData)

        }
    }, [wmsLogData])


    return (
        <Flex direction={'column'} gap="sm">
            <PreWareHouseList.Header>
                <PreWareHouseList.HeaderTitle>수입검사</PreWareHouseList.HeaderTitle>
                <PreWareHouseList.HeaderSubTitle>입고 된 목록을 확인할 수 있습니다.</PreWareHouseList.HeaderSubTitle>
            </PreWareHouseList.Header>
            <SearchBox searchType={preWarehouseListHeader} setSearch={setSearch} />
            <GridTable
                headerList={[
                    SelectColumn,
                    ...columnlist.preWarehouseList
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={500}
                resizable
            // scrollEnd={(value) => {
            //     if(value){
            //         if(keyword !== undefined){
            //             if(searchPage.total_page > searchPage.current_page){
            //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
            //             }
            //         }else{
            //             if(page.total_page > page.current_page){
            //                 getPressDailyStatusApi(page.current_page+ 1)
            //             }
            //         }
            //     }
            // }}
            />
            {/* 페이지네이션 */}
            <Flex justify={'center'} align={'center'}>
                <Pagination
                    onChange={setPage}
                    value={page}
                    total={wmsLogData?.data.totalPages ?? 0}
                />
            </Flex>
        </Flex>
    )
}