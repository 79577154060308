import { mutatePurchaseOrders } from "@/api/purchaseOrder/usePurchaseOrder";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ActionBarContainer, LeftActionBar, RightActionBar } from "@/features/work/WorkView/ActionBar";
import { getUserCodeByUser } from "@/utils/checkData";
import { Button } from "@mantine/core";
import { ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconRefresh, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";
import { PurchaseOrderStatusForm } from "../form/statusForm";
import { PurchaseOrderUpdateForm } from "../form/updateForm";
import { PurchaseOrderDetailHeaderProps } from "./purchaseOrderDetailHeader";


export const PurchaseOrderDetailActionBar = (params: PurchaseOrderDetailHeaderProps) => {
    const { data } = params;
    const userCode = getUserCodeByUser();
    const { openModal } = useModal();
    const queryClient = useQueryClient();
    const { mutate: deleteMutate } = useMutation(
        (params: ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest) =>
            mutatePurchaseOrders.delete(params).mutationFn(params as any | ScmApiPurchaseOrdersPurchaseOrderIdDeleteRequest),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("purchaseOrders");
            }
        }
    );

    const deletePurchaseOrder = () => {
        if (window.confirm("정말 삭제하시겠습니까?")) {
            deleteMutate(
                {
                    purchaseOrderId: data?.id ?? 0
                },
                {
                    onSuccess: (res) => {
                        if (res.status === 200) {
                            customAlert(
                                `${userCode === "PB" ? "발주 삭제에 성공하였습니다." : "수주 삭제에 성공하였습니다."}`,
                                "삭제 성공",
                                "green"
                            )
                        }
                    },
                    onError: (error : any) => {
                        customAlert(
                            `${error.response?.data?.message}` ?? (`${userCode === "PB" ? "발주 삭제에 성공하였습니다." : "수주 삭제에 성공하였습니다."}`),
                            "삭제 실패",
                            "red"
                        ) 
                    }
                }
            );
        }
    };

    return (
        <ActionBarContainer>
            <LeftActionBar>
                <Button
                    variant="outline"
                    leftIcon={<IconRefresh />}
                    onClick={() => openModal(
                        <PurchaseOrderStatusForm formatterProps={data} />,
                        null,
                        `${userCode === "PB" ? '발주상태 변경' : '수주상태 변경'}`
                    )}
                >{userCode === "PB" ? '발주상태 변경' : '수주상태 변경'}</Button>
            </LeftActionBar>

            <RightActionBar>
                <Button
                    color="orange"
                    rightIcon={<IconEdit />}
                    onClick={() => openModal(
                        <PurchaseOrderUpdateForm formatterProps={data} />,
                        null,
                        `${userCode === "PB" ? '발주 수정' : '수주 수정'}`
                    )}
                >수정</Button>
                <Button color="red"
                    rightIcon={<IconTrash />}
                    onClick={deletePurchaseOrder}
                >삭제</Button>
            </RightActionBar>
        </ActionBarContainer>
    )
}