import { analysis } from '@/api/analysis/useAnalysisQuery';
import { customFunctions } from '@/config/customFunction';
import { useModal } from '@/features/modal/ModalStackManager';
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import customAlert from '@/features/ui/alert/alert';
import { Calendar, isCalendarDate } from '@/features/ui/Calendar';
import { useLoader } from '@/hooks/useLoader';
import { convertDataToSeriesType } from '@/pages/equipmentAnalysis/logic';
import { setDateFormatStringWithTime } from '@/utils/dateTimeUtil';
import timeUtil from '@/utils/timeUtil';
import { ActionIcon, Flex, MultiSelect, Text } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import { ApexOptions } from 'apexcharts';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';


const groupColors = [
    '#FF0000', '#0000FF', '#FF8C00', '#8B008B', '#1E90FF', '#8B0000', '#4B0082', '#006400', '#483D8B', '#2F4F4F',
    '#8B4513', '#2E8B57', '#228B22', '#4682B4', '#D2691E', '#A52A2A', '#5F9EA0', '#556B2F', '#8B0000', '#000080'
];

const applyCustomStyles = () => {
    // 같은 그룹끼리 그룹화 하기 위한 라인 제거
    document.querySelectorAll('.apexcharts-gridline').forEach((label: any, index) => {
        if (index % 2 === 0) label.style.display = 'none';
    });
    // 같은 그룹끼리 라벨 색상 동일하게
    document.querySelectorAll('.apexcharts-yaxis-label').forEach((label: any, index) => {
        label.style.fill = groupColors[Math.floor(index / 2) % groupColors.length];
    });
}

export type SeriesType = {
    name: string;
    data: {
        x: string;
        y: number[] | null | number | undefined;
        downtimeReason?: string
    }[];
}

const getDefaultChartOptions: ApexOptions = {
    chart: {
        height: 350,
        type: 'rangeBar',
        events: {
            mounted: () => {
                applyCustomStyles();
            },
            updated: () => {
                applyCustomStyles();
            },
            zoomed: () => {
                applyCustomStyles();
            },
        },
        animations: {
            enabled: false
        }
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: '50%',
            rangeBarGroupRows: true,
        },
    },
    dataLabels: { enabled: false },
    colors: ['#28A745', '#FFC107'],
    fill: { type: 'solid' },
    yaxis: {
        labels: {
            style: { fontSize: '13px', fontWeight: 900 },
            formatter: (val, opt) => {
                const valString = val.toString();
                if (customFunctions.ADD_REMOVE_EQUIPMENT_ANALYSIS_COUNTER_LABEL) {
                    if (valString.includes('카운터') && !valString.includes('POP')) {
                        return '';
                    }
                }
                return valString;
            },
        },
    },
    xaxis: {
        type: 'datetime',
        labels: {
            formatter: (val) => new Date(val).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        },
        tickAmount: 24,
    },
    legend: { position: 'right' },
    tooltip: {
        enabled: true,
        custom(params) {
            const { seriesIndex, y1, y2, dataPointIndex, w } = params

            const isActiveTime = seriesIndex === 0;
            const seriesName = isActiveTime ? '가동시간' : '비가동시간';
            const color = isActiveTime ? '#28A745' : '#FFC107';
            const downtimeReason = seriesName === '비가동시간' &&
                w.globals.initialSeries[seriesIndex]?.data[dataPointIndex]?.downtimeReason?.name;

            return `
        <div class="apexcharts-tooltip-rangebar">
          <div class="series-info">
            <span class="series-name" style="color: ${color}">${seriesName}</span>
            <span class="value">${setDateFormatStringWithTime(y1)} ~ ${setDateFormatStringWithTime(y2)}</span> 
          </div>
           ${downtimeReason ? `
          <div class="downtime-reason-info">
            <span class="series-name" style="color: red">사유</span>
            <span class="value">${downtimeReason}</span>
          </div>`
                    : ' '}
        </div>
      `;
        },
    },
};

const EquipmentAnalysisWrap = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
});

export const EquipmentAnalysisPage = () => {

    const { openModal } = useModal();
    const today = dayjs().startOf('day').toDate();
    const [stateDate, setStateDate] = useState<[Date | null, Date | null]>([today, today]);
    const [selectedValue, setSelectedValue] = useState<string[]>([]);
    const [options, setOptions] = useState<ApexOptions>(getDefaultChartOptions);
    const { LoadingOverlay, setLoading } = useLoader();

    const { data: analysisData, isLoading } = useQuery({
        ...analysis.get({
            equipmentsAnalysisPostRequest:
                { scheduledAt: stateDate[0]?.toISOString() }
        }),
        onSuccess: () => {
            setLoading(false);
        },
        onError: (error: AxiosError<Error>) => {
            customAlert(
                error.response?.data?.message ?? '분석 데이터를 가져오는데 실패하였습니다.',
                "등록 실패",
                "red"
            )
        },
        staleTime: 0,  // 캐시 데이터를 사용하지 않도록 설정
        cacheTime: 0,
        enabled: !!stateDate[0]
    })

    const influxData = analysisData?.data.influx
    const popData = analysisData?.data.pop

    const seriesData = useMemo(() => convertDataToSeriesType({ popData, influxData }), [popData, influxData]);

    const handleMultiSelect = useCallback((value: string[]) => {
        setSelectedValue(value.flatMap((val) => val.split(',').map((v) => v.trim())));
    }, []);

    const dailyData = useMemo(() => {
        return selectedValue.length === 0
            ? seriesData
            : seriesData.map((series) => ({
                ...series,
                data: series.data.filter((item) => selectedValue.includes(item.x)),
            }));
    }, [seriesData, selectedValue]);

    useEffect(() => {
        const date = new Date(stateDate[0] as Date);
        const adjustedMin = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0).getTime();
        const adjustedMax = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0).getTime();

        setOptions(prevOptions => ({
            ...prevOptions,
            xaxis: {
                ...prevOptions.xaxis,
                min: adjustedMin,
                max: adjustedMax
            },
        }));

    }, [stateDate]);

    useEffect(() => {
        if (isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isLoading, setLoading]);

    return (
        <>
            <LoadingOverlay />
            <EquipmentAnalysisWrap.Wrapper>
                <Flex justify={'center'} align={'center'} gap="sm" w={'100%'}
                    onClick={() =>
                        openModal(<Calendar dates={stateDate} type="default" />, null, '날짜 선택')
                            .then((value) => (isCalendarDate(value)) && setStateDate(value))}>
                    <Text size="2rem" fw="bold" style={{ cursor: 'pointer' }}>
                        {timeUtil(stateDate[0]?.toISOString() ?? '')}
                    </Text>
                    <ActionIcon>
                        <IconCalendar size="2rem" />
                    </ActionIcon>
                </Flex>
                <EquipmentAnalysisWrap.Header>
                    <EquipmentAnalysisWrap.HeaderTitle>설비별 통계성 데이터</EquipmentAnalysisWrap.HeaderTitle>
                    <EquipmentAnalysisWrap.HeaderSubTitle>
                        우리 회사에서 다루는 설비 가동/비가동 통계 조회할 수 있는 페이지 입니다.
                    </EquipmentAnalysisWrap.HeaderSubTitle>
                </EquipmentAnalysisWrap.Header>
                <Flex direction="column" w="100%" h={'100%'}>
                    <Flex justify={'flex-end'}>
                        <MultiSelect
                            w={'40%'}
                            label="설비 선택"
                            placeholder="설비를 선택하세요"
                            onChange={handleMultiSelect}
                            data={[...new Set(seriesData[0].data.map((item: any) => item.x))] // 중복 제거
                                .reduce((acc: string[], cur, idx, arr) => (idx % 2 === 0 ? [...acc, `${cur}, ${arr[idx + 1] || ''}`] : acc), [])}
                            clearButtonProps={{ 'aria-label': 'Clear selection' }}
                            clearable
                            mb={'xl'}
                        />
                    </Flex>
                    <div id="chart" style={{ width: '100%', height: '100%' }}>
                        <ReactApexChart
                            width="100%"
                            options={options}
                            series={dailyData ?? []}
                            type="rangeBar"
                            height={'100%'} />
                    </div>
                </Flex>
            </EquipmentAnalysisWrap.Wrapper>
        </>
    );
};