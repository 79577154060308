import { SeriesType } from "@/pages/equipmentAnalysis";
import { EquipmentsAnalysisPost200ResponseInfluxValue, EquipmentsAnalysisPost200ResponsePopValueInner } from "@sizlcorp/sizl-api-document/dist/models";


type DataType = {
    popData: { [key: string]: EquipmentsAnalysisPost200ResponsePopValueInner[] } | undefined,
    influxData: { [key: string]: EquipmentsAnalysisPost200ResponseInfluxValue } | undefined
}

type CombineDataType = {
    key: string;
    equipmentName: string;
    popEntries: EquipmentsAnalysisPost200ResponsePopValueInner[] | undefined;
    timePeriods: string[] | undefined;
}

const combineData = ({ popData, influxData }: DataType) => {
    const combined: CombineDataType[] = [];

    // popData와 influxData의 모든 key를 하나의 배열로 결합
    const allKeys = new Set([
        ...Object.keys(popData || {}),
        ...Object.keys(influxData || {}),
    ]);

    allKeys.forEach((key) => {
        const popEntries = popData?.[key] || undefined; // popData가 없으면 undefined
        const influxEntry = influxData?.[key] || { name: '', timePeriods: undefined }; // influxData가 없으면 timePeriods는 undefined

        // popEntries 또는 influxEntry의 timePeriods 중 하나라도 있으면 데이터 추가
        if (popEntries || influxEntry.timePeriods) {
            combined.push({
                key,
                equipmentName: influxEntry.name || (popEntries?.[0]?.equipment?.name || 'Unknown'), // influxData에 name이 없으면 popData의 장비 이름 사용
                popEntries,
                timePeriods: influxEntry.timePeriods // timePeriods가 없으면 undefined
            });
        }
    });

    return combined;
};

export const convertDataToSeriesType = ({ popData, influxData }: DataType) => {

    const result: SeriesType[] = [
        {
            name: "가동시간",
            data: []
        },
        {
            name: "비가동시간",
            data: []
        }
    ];

    const combined = combineData({ popData, influxData });


    combined.forEach(({ equipmentName, popEntries, timePeriods }) => {

        if (timePeriods) {
            const [influxOperatingTimes, influxDowntimeTimes] = categorizeInfluxTimes(timePeriods)
            for (const time of influxOperatingTimes) {
                result[0].data.push({
                    x: `${equipmentName}`,
                    y: [time.from, time.to]
                })
            }
            for (const time of influxDowntimeTimes) {
                result[1].data.push({
                    x: `${equipmentName}`,
                    y: [time.from, time.to]
                })
            }
        } else {
            result[0].data.push({
                x: `${equipmentName}`,
                y: 0
            })
        }

        if (popEntries) {
            const [popOperatingTimes, popDowntimeTimes] = categorizePopEntries(popEntries)
            for (const time of popOperatingTimes) {
                result[0].data.push({
                    x: `${equipmentName}(POP)`,
                    y: [time.from, time.to]
                })
            }
            for (const time of popDowntimeTimes) {
                result[1].data.push({
                    x: `${equipmentName}(POP)`,
                    y: [time.from, time.to],
                    downtimeReason: time.downtimeReason
                })
            }
        } else {
            result[0].data.push({
                x: `${equipmentName}(POP)`,
                y: 0
            })
        }
    });

    return result
}


export function categorizePopEntries(entries: EquipmentsAnalysisPost200ResponsePopValueInner[] | undefined) {

    let lastStart: any = null;
    let lastPause: any = null;
    let lastResume: any = null;
    let lastEnd: any = null;

    const operatingTimes: any = [];
    const downtimeTimes: any = [];

    entries?.forEach((log, index: number) => {
        const timestamp = new Date(log.createdAt as string).getTime();

        switch (log.workLogType) {

            case 'TIME_TRACKING_START':
                if (lastPause) {
                    downtimeTimes.push({
                        from: lastPause.timestamp,
                        to: timestamp,
                        downtimeReason: lastPause.downtimeReason
                    });
                    lastPause = null;
                } else if (lastEnd) {
                    operatingTimes.push({
                        from: lastEnd.timestamp,
                        to: timestamp,
                    });
                    lastEnd = null;
                }
                lastStart = { timestamp, downtimeReason: log.downtimeReason };
                break;

            case 'TIME_TRACKING_PAUSE':
                if (lastStart) {
                    operatingTimes.push({
                        from: lastStart.timestamp,
                        to: timestamp,
                    });
                    lastStart = null;
                } else if (lastResume) {
                    operatingTimes.push({
                        from: lastResume.timestamp,
                        to: timestamp,
                    });
                    lastResume = null;
                }
                lastPause = { timestamp, downtimeReason: log.downtimeReason };
                break;

            case 'TIME_TRACKING_RESUME':
                if (lastPause) {
                    downtimeTimes.push({
                        from: lastPause.timestamp,
                        to: timestamp,
                        downtimeReason: lastPause.downtimeReason
                    });
                    lastPause = null;
                }
                lastResume = { timestamp, downtimeReason: log.downtimeReason };
                break;

            case 'TIME_TRACKING_END':
                if (lastStart) {
                    operatingTimes.push({
                        from: lastStart.timestamp,
                        to: timestamp,
                    });
                    lastStart = null;
                    lastEnd = { timestamp, downtimeReason: log.downtimeReason };
                } else if (lastResume) {
                    operatingTimes.push({
                        from: lastResume.timestamp,
                        to: timestamp,
                    });
                    lastResume = null;
                    lastEnd = { timestamp, downtimeReason: log.downtimeReason };
                }
                break;
        }

        if (index === entries.length - 1) {
            if (lastStart) {
                operatingTimes.push({
                    from: lastStart.timestamp,
                    to: timestamp,
                });
            } else if (lastResume) {
                operatingTimes.push({
                    from: lastResume.timestamp,
                    to: timestamp,
                });
            } else if (lastPause) {
                downtimeTimes.push({
                    from: lastPause.timestamp,
                    to: timestamp,
                    downtimeReason: lastPause.downtimeReason
                });
            }
        }
    });

    return [operatingTimes, downtimeTimes]
};


function categorizeInfluxTimes(timePeriods: string[]) {

    const operatingTimes = [];
    const downtimeTimes = [];

    for (const timePeriod of timePeriods) {
        if (timePeriod !== undefined) {
            const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z)\s*~\s*(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z)/;
            const match = timePeriod.match(regex) as RegExpMatchArray;
            const from = new Date(match[1]).getTime();
            const to = new Date(match[2]).getTime();

            if (timePeriod.includes("비가동")) {
                downtimeTimes.push({ from: from, to: to });
            } else {
                operatingTimes.push({ from: from, to: to });
            }
        }
    }
    return [operatingTimes, downtimeTimes]
}
