import { mutateRoutingOutsources } from "@/api/routingOutsource/useRoutingOutsourcesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingOutsourceCreateForm } from "@/features/RoutingOutsource/form/CreateForm";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { DefaultApiRoutingOutsourcesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import customAlert from "../../alert/alert";
import {
  RoutingOutsourceOption,
  useRoutingOutsourcesAutoComplete,
  useRoutingOutsourcesCode,
} from "./auto-useGetRoutingOutsourcesQuery";

interface RoutingOutsourceProps
  extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface RoutingOutsourceAutoCompleteProps
  extends Partial<SelectProps>,
  Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  setName: (name: string) => void;
  width?: string;
}

export const RoutingOutsourceAutoComplete = (
  params: RoutingOutsourceAutoCompleteProps
) => {
  const {
    value: routingOutsourceCode,
    onChange,
    maxDropdownHeight,
    setName,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [changeSelect, setChangeSelect] = useState<string>("");

  const { data: options } = useRoutingOutsourcesAutoComplete(
    focused,
    searchKeyword
  );

  const { data: initialOptions } = useRoutingOutsourcesCode(
    !!changeSelect,
    changeSelect ?? null
  );

  let selectedRoute = initialOptions?.find((item) => {
    return item.value === routingOutsourceCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedRoute = options?.find((item) => item.value === e);
    setChangeSelect(selectedRoute?.label ?? "");
    setName(selectedRoute?.label ?? "");
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, RoutingOutsourceProps>(
    ({ label: name, value: code, group, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{group}</Text>
            <Text fz="xs"> (name: {name === "null" ? "-" : name})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <RoutingOutsourceSelect
      styles={
        routingOutsourceCode
          ? {
            input: {
              width: width,
              fontSize: "0.8em",
              paddingBottom: "0.5rem",
            },
          }
          : { input: { width: width } }
      }
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedRoute?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedRoute?.value}
            </div>
          )}
        </div>
      )}
      value={routingOutsourceCode}
      itemComponent={SelectItem}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (
          unique: RoutingOutsourceProps[],
          option: RoutingOutsourceProps
        ) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={RoutingOutsourceInfo({
        routingOutsource: selectedRoute as RoutingOutsourceOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewRoutingOutsource({
        routingOutSourceName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const RoutingOutsourceInfo = (params: {
  routingOutsource?: RoutingOutsourceOption;
  onChange: (operationCode: string | null) => void;
}) => {
  const { routingOutsource, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return routingOutsource?.value ? (
    <RoutingOutsourceInfoLabel>
      {/* ({routingOutsource.label})  */}
      <IconX size="1rem" onClick={clearHandler} />
    </RoutingOutsourceInfoLabel>
  ) : null;
};

const AddNewRoutingOutsource = (params: {
  routingOutSourceName: string;
  onChange: (itemCode: string) => void;
}) => {
  const { routingOutSourceName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: DefaultApiRoutingOutsourcesPostRequest) =>
      mutateRoutingOutsources.create(params).mutationFn(params as DefaultApiRoutingOutsourcesPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('routingOutsources')
        closeModal(res.data);
        customAlert(
          "외주처 생성에 성공하였습니다.",
          "생성 성공",
          "green"
        )
      },
      onError: (error) => {
        customAlert(
          "외주처 생성에 실패하였습니다.",
          "생성 실패",
          "red"
        )
      }
    }
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate(
      {
        routingOutsourcesGetRequest: {
          routingCode: values.routingCode,
          name: values.name,
          fromLocationCode: values.fromLocationCode,
          toLocationCode: values.toLocationCode,
        },
      },
    );
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <RoutingOutsourceCreateForm
        name={routingOutSourceName}
        autoCompleteOnSubmit={onCloseHandler}
      />,
      null,
      "신규 외주처 등록",
      true
    ).then((result) => {
      onChange(result.name);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 외주처({routingOutSourceName}) 등록
    </Button>
  );
};
const RoutingOutsourceInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const RoutingOutsourceSelect = styled(Select)`
  width: 100%;
`;
