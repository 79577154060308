import { items } from "@/api/items/useItemQuery"
import { columnlist } from "@/constants/columnlist"
import { GridTable } from "@/features/ui/PMS/GridTable"
import { Button, Flex, Input, Pagination, Select, Text } from "@mantine/core"
import { useEffect, useState } from "react"
import { SelectColumn } from "react-data-grid"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"

export const WorkStandardListPage = () => {

    const [content, setContent] = useState<any>([]);
    const [selectList, setSelectList] = useState<Set<number>>(new Set())
    const { t } = useTranslation();
    const [page, setPage] = useState<number>();

    const { data } = useQuery({
        ...items.get({
            page: page
        })
    })

    useEffect(() => {
        if (data?.data) {
            const transformedData = data.data.rows?.map((item: any) => ({
                id: item.id,
                code: item.code,
                name: item.name,
                spec: item.spec,
                type: t(item.itemType),
            }));
            setContent(transformedData)

        }
    }, [data])

    return (
        <Flex direction={'column'} gap="md">
            <Flex justify={'space-between'}>
                <Text size="lg">작업 표준서 관리</Text>
                <Flex gap="sm">
                    <Select
                        w={'8rem'}
                        data={[
                            { label: '코드', value: 'code' },
                            { label: '품명', value: 'name' },
                        ]}
                    />
                    <Input
                        w={'16rem'}
                        placeholder="검색어를 입력하세요"
                    />
                    <Button>검색</Button>
                </Flex>
            </Flex>
            <GridTable
                headerList={[
                    SelectColumn,
                    ...columnlist.qualityWorkstandard
                ]}
                row={content}
                setRow={setContent}
                selectList={selectList}
                //@ts-ignore
                setSelectList={setSelectList}
                width={'100%'}
                height={500}
            // scrollEnd={(value) => {
            //     if(value){
            //         if(keyword !== undefined){
            //             if(searchPage.total_page > searchPage.current_page){
            //                 getPressDailyStatusSearchApi(keyword, searchPage.current_page+ 1)
            //             }
            //         }else{
            //             if(page.total_page > page.current_page){
            //                 getPressDailyStatusApi(page.current_page+ 1)
            //             }
            //         }
            //     }
            // }}
            />
            {/* 페이지네이션 */}
            <Flex justify={'center'} align={'center'}>
                <Pagination
                    onChange={setPage}
                    value={page}
                    total={data?.data.totalPages ?? 0}
                />
            </Flex>
        </Flex>
    )
}