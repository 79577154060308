import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { AuthSignupPost201ResponseEquipment, MasterApiEquipmentsEquipmentIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { useModal } from "@/features/modal/ModalStackManager";
import { useSub } from "@/hooks";

import { equipments, mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { equipmentsHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { EquipmentUpdateForm } from "@/features/equipment/components/form/EquipmentUpdateForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import customAlert from "@/features/ui/alert/alert";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import { useMutation, useQuery } from "react-query";
import { EquipmentDetailForm } from "../form/EquipmentDetailForm";

interface Row {
  id: number;
  siteCode: string;
  equipmentType?: string;
  code: string;
  seq?: number;
  volt?: number;
  toLocationCode?: string;
  toLocationName?: string;
  targetLocationCode?: string;
  targetLocationName?: string;
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const EquipmentTable = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );
  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });
  const updateUrlParams = useUpdateUrlParams();

  const { openModal } = useModal();

  const { data: equipment, refetch } = useQuery(equipments.get({
    query: query,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : equipmentsHeader.map((equipment) => equipment.value),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "seq, name, code",
    populate: ['location', "works"],
  }));

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (equipment && activePage > (equipment?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [equipment, activePage, setQueryString]);

  // 페이지 Query String으로 공정 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly AuthSignupPost201ResponseEquipment[] =
    equipment?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiEquipmentsEquipmentIdDeleteRequest) =>
      mutateEquipment.delete(params).mutationFn(params as MasterApiEquipmentsEquipmentIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const columns: readonly Column<Row>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "siteCode",
      name: "사업장코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink onClick={() => openModal(<SiteDetailForm siteCode={formatterProps.row.siteCode} />, null, "")}>{formatterProps.row.siteCode}</DetailLink>
        )
      },
    },
    {
      key: "code",
      name: "설비코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink onClick={() => openModal(<EquipmentDetailForm equipmentCode={formatterProps.row.code} />, null, "")}>{formatterProps.row.code}</DetailLink>
        )
      },
    },
    {
      key: "name",
      name: "설비명",
      sortable: true,
      resizable: true,
    },
    {
      key: "equipmentType",
      name: "설비유형",
      sortable: true,
      resizable: true,
    },
    {
      key: "volt",
      name: "전압",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
    },
    {
      key: "toLocationName",
      name: "입고창고",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.toLocationCode} />,
                null,
                ""
              )
            }
          >
            {formatterProps.row.toLocationName}
          </DetailLink>
        )
      },
    },
    {
      key: "targetLocationName",
      name: "품목 생산 창고",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.targetLocationCode} />, null,
                ""
              )
            }
          >
            {formatterProps.row.targetLocationName}
          </DetailLink>
        )
      },
    },
    {
      key: "seq",
      name: "시퀀스(순서)",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const deleteItemAction = async (tid: number) => {
          if (window.confirm("정말로 삭제하시겠습니까?")) {
            deleteItem(tid);
          }
        };

        // delete item after confirm
        const deleteItem = async (deleteItemId: number) => {
          deleteMutate({ equipmentId: deleteItemId }, {
            onSuccess: (res) => {
              if (res.status === 200) {
                customAlert(
                  "설비 삭제에 성공하였습니다.",
                  "삭제 성공",
                  "green"
                );
              } else {
                customAlert("설비 삭제에 실패하였습니다.", "삭제 실패", "red");
              }
            },
          });
        };

        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                openModal(
                  <EquipmentUpdateForm formatterProps={formatterProps?.row} />,
                  null,
                  "설비 수정",
                  true
                );
              }}
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                deleteItemAction(formatterProps?.row?.id);
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  useSub("deleteSelectedItems", () => {
    const rows = Array.from(selectedRowsRef.current);
    if (!rows.length) return;
    const deleteRows: number[] = Array.from(rows[rows.length - 1].values());
    if (window.confirm("선택한 행을 삭제하시겠습니까?")) {
      deleteRows.forEach((row: number) => {
        deleteMutate({ equipmentId: row });
      });
      selectedRowsRef.current = new Set();
    }
  });

  return (
    <EquipmentTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={equipmentsHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={equipmentsHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox searchType={equipmentsHeader} setSearch={setSearch} search={search} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows}
            rowHeight={40}
            rowKeyGetter={(row: Row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={equipment?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </EquipmentTableWrapper>
  );
};

const EquipmentTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
